import React, { useState, useEffect } from "react";
import "./../../css/chat-bots.scss";
import { Container, Row, Col } from "react-bootstrap";

import "react-multi-carousel/lib/styles.css";
import Techstacks from "./../../component/techstacks";

import chatbots11x from "./../../images/chatbots/chatbots-1-1x.png";
import chatbots12x from "./../../images/chatbots/chatbots-1-2x.png";
import chatbots21x from "./../../images/chatbots/chatbots-2-1x.png";
import chatbots22x from "./../../images/chatbots/chatbots-2-2x.png";
import chatbots31x from "./../../images/chatbots/chatbots-3-1x.png";
import chatbots32x from "./../../images/chatbots/chatbots-3-2x.png";
import chatbots41x from "./../../images/chatbots/chatbots-4-1x.png";
import chatbots42x from "./../../images/chatbots/chatbots-4-2x.png";
import chatbots51x from "./../../images/chatbots/chatbots-5-1x.png";
import chatbots52x from "./../../images/chatbots/chatbots-5-2x.png";

import imgNumber1 from "./../../img/imgNumber1.svg";
import imgNumber2 from "./../../img/imgNumber2.svg";
import imgNumber3 from "./../../img/imgNumber3.svg";
import imgNumber4 from "./../../img/imgNumber4.svg";
import imgNumber5 from "./../../img/imgNumber5.svg";
import imgConclusion from "./../../img/imgConclusion.svg";

function ChatBots(props) {

	const [chatbots1WD, setChatbots1WD] = useState('');
	const [chatbots2WD, setChatbots2WD] = useState('');
	const [chatbots3WD, setChatbots3WD] = useState('');
	const [chatbots4WD, setChatbots4WD] = useState('');
	const [chatbots5WD, setChatbots5WD] = useState('');
	
   useEffect(() => {
	  if( props.wd < 1200 ){
		  setChatbots1WD(chatbots11x);
		  setChatbots2WD(chatbots21x);
		  setChatbots3WD(chatbots31x);
		  setChatbots4WD(chatbots41x);
		  setChatbots5WD(chatbots51x);
	  } else if( props.wd >= 1200 ){
		  setChatbots1WD(chatbots12x);
		  setChatbots2WD(chatbots22x);
		  setChatbots3WD(chatbots32x);
		  setChatbots4WD(chatbots42x);
		  setChatbots5WD(chatbots52x);
	  }
   },[props.wd]);
  
  return (
    <div>
      <Container>
        <div className="wrapBG">
          <Row className="wrapRowChatBotsText">
            <Col xl="6" lg="6" md="12" sm="12">
              <h2 className="h2ChatBotsText">
                <span className="h2ChatBotsTextBlue">Chat</span>Bots
              </h2>
              <p className="pChatBotsText">
                We specialize in the development and deployment of chat solutions and chatbots tailored to enhance the customer support process within insurance companies' contact centers.
              </p>
              <a href="/en/contact" className="btn buttonOne">Contact us</a>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img src={chatbots1WD} alt=" " className="imgChatBots" />
            </Col>
          </Row>
          <Row className="wrapRowTechStacksText">
            <Col xl="12" lg="12" md="12" sm="12">
              <h2 className="h2TechStacksText">Tech stacks</h2>
            </Col>
          </Row>
          <Row className="wrapRowTechStacksImg">
            <Col xl="12" lg="12" md="12" sm="12">
              <div id="marquee1" className="marquee">
                <div id="blockSlider" className="marquee__inner">
                  <Techstacks />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="wrapRowCustomerProblems">
            <Col xl="6" lg="6" md="12" sm="12">
              <h2 className="h2CustomerProblems">CUSTOMER CHALLENGES</h2>
              <div className="wrapTextCustomerProblems">
              <p className="pCustomerProblems">
                Our client aimed to shift a portion of customer inquiries from the phone channel to a chat-based platform. The primary objective was to alleviate the workload on call center operators, introduce automation, and reduce customer support costs.
                  <br/>
                  <br/>
                The client's company faced a diverse range of requests that could be efficiently handled through chat, including:
                </p>
                <ul className="ulCustomerProblems">
                  <li> Verifying insurance claim statuses.</li>
                  <li> Scheduling routine medical check-ups.</li>
                  <li> Confirming branch locations.</li>
                  <li> Addressing various other inquiries.</li>
                </ul>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img
                src={chatbots2WD}
                alt=""
                className="imgCustomerProblems"
              />
            </Col>
          </Row>
          <Row className="wrapRowCustomerProblems2">
            <Col xl="6" lg="6" md="12" sm="12">
              <img
                src={chatbots3WD}
                alt=""
                className="imgCustomerProblems2top"
              />
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <div className="wrapTextCustomerProblems">
                <p className="pCustomerProblems">
                  Many of these requests from both clients and the insurance company can be resolved more efficiently, cost-effectively, and swiftly through chat-based interactions. Additionally, several other inquiries can also be automated.
                </p>
                <p className="pCustomerProblems">
                  Customization was a key consideration for the client during the implementation of this solution. They needed the flexibility to tailor their specific business processes for managing chat interactions. This customization included:
                </p>
                <ul className="ulCustomerProblems">
                  <li> Routing chat sessions to the relevant user groups.</li>
                  <li> Designating chat agents, supervisors, and observers.</li>
                  <li> Managing chat history and segmenting chat sessions based on the conversation topic.</li>
                  <li> Monitoring the status of chat sessions.</li>
                </ul>
                <p className="pCustomerProblems">
                  This level of customization ensured that the solution aligned seamlessly with the client's unique operational requirements.
                </p>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img
                src={chatbots3WD}
                alt=""
                className="imgCustomerProblems2bottom"
              />
            </Col>
          </Row>
          <Row className="wrapRowDevelopmentProcessText">
            <Col xl="12" lg="12" md="12" sm="12">
              <h2 className="h2DevelopmentProcess">DEVELOPMENT PROCESS</h2>
            </Col>
          </Row>
          <Row className="wrapRowDevelopmentProcessBlock">
            <Col xl="12" lg="12" md="12" sm="12">
              <div className="itemImgNumber itemImgNumber1">
                <img src={imgNumber1} alt="" className="imgNumber imgNumber1" />
                <p className="pDevelopmentProcess">
                  <b>Solution Concept and Problem Definition:</b>
                  <br/>- Collaborate with the client to establish the general concept of the solution and define the primary problems to be addressed. This step sets the foundation for the project.
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber2">
                <img src={imgNumber2} alt="" className="imgNumber imgNumber2" />
                <p className="pDevelopmentProcess">
                  <b>Regular Status Meetings:</b>
                  <br/>- Conduct weekly status meetings with the project sponsor and key members of the working group. These meetings serve as a platform for ongoing communication and alignment with project goals.
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber3">
                <img src={imgNumber3} alt="" className="imgNumber imgNumber3" />
                <p className="pDevelopmentProcess">
                  <b>Action Plans and Concept Agreements:</b>
                  <br/>- Develop action plans and agree upon solution concepts and mockups. This iterative process helps in creating a shared vision and understanding of the solution's direction.
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber4">
                <img src={imgNumber4} alt="" className="imgNumber imgNumber4" />
                <p className="pDevelopmentProcess">
                  <b>Demonstrating Development Progress:</b>
                  <br/>Showcase intermediate development results to the client and project stakeholders. These demonstrations provide transparency and allow for early feedback and adjustments.
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber5">
                <img src={imgNumber5} alt="" className="imgNumber imgNumber5" />
                <p className="pDevelopmentProcess">
                  <b>Scalability Planning:</b>
                  <br/>Discuss and adjust scalability plans for the future of the solution. Scalability is crucial to accommodate growing demands and changes in the insurance industry.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="wrapRowResultsText">
            <Col xl="12" lg="12" md="12" sm="12">
              <h2 className="h2DevelopmentProcess">Results</h2>
            </Col>
          </Row>
          <Row className="wrapRowResults1Block">
            <Col xl="6" lg="6" md="12" sm="12">
              <div className="wrapResults1">
                <p className="pResults1">
                  The development process spanned a period of 3 months, during which our team successfully crafted a solution that effectively addressed all the requirements specified by the client.
                </p>
                <p className="pResults1">
                  Following the development phase, we provided training to the client's employees, enabling them to fully utilize the system. Subsequently, the solution was launched into production. <br />
                  Upon the system's deployment, it resulted in the transition about 10% of customer inquiries from the phone channel to the chat platform. This not only achieved the client's goal of relieving call center operators and reducing support costs but also enhanced the efficiency and convenience of customer interactions.
                </p>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img src={chatbots4WD} alt="" className="imgResult1" />
            </Col>
          </Row>
          <Row className="wrapRowResults2Block">
            <Col xl="6" lg="6" md="12" sm="12" className="wrapImgResult2Top">
              <img
                src={chatbots5WD}
                alt=""
                className="imgResult2 imgResult2BTop"
              />
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <div className="wrapResults2">
                <p className="pResults1">
                  Furthermore, the successful implementation of this solution opened a new avenue for communication with customers. This means that in the future, the client can establish additional business processes, such as:
                </p>
                <ul className="ulCustomerProblems">
                  <li>Sales</li>
                  <li>Resolution</li>
                  <li>Marketing</li>
                </ul>
                <p className="pResults1">
                  The upcoming phases of the project development involve more robust integration of the solution into the client's IT landscape. This will serve to enrich the overall customer experience and further enhance user convenience. The foundation has been laid for continued innovation and evolution in how the client interacts with and serves its customers.
                </p>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12" className="wrapImgResult2Bottom">
              <img
                src={chatbots5WD}
                alt=""
                className="imgResult2 imgResult2Bottom"
              />
            </Col>
          </Row>
          <Row className="wrapRowConclusion">
            <Col xl="6" lg="6" md="12" sm="12">
              <div className="wrapConclusionText">
                <h2 className="h2Conclusion">Conclusion</h2>
                <p className="pConclusion">
                  This case study serves as a compelling example of how, even during challenging periods like quarantine, the launch of new solutions can effectively assist insurance companies in reaching and attracting new customers. Notably, this solution has the added benefit of enabling individuals without prior experience in insurance to generate additional income. It underscores the value of innovation and adaptability in transforming customer engagement and business operations.
                </p>
                <a href="/en/contact" className="btn buttonOne">Contact us</a>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img src={imgConclusion} alt="" className="imgConclusion" />
            </Col>
          </Row>
        </div>
      </Container>
    </div>

  );
}

export default ChatBots;
