import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./../../css/online-agent.scss";

import "react-multi-carousel/lib/styles.css";
import Techstacks from "./../../component/techstacks";

import onlineagent11x from "./../../images/onlineagent/onlineagent-1-1x.png";
import onlineagent12x from "./../../images/onlineagent/onlineagent-1-2x.png";
import onlineagent21x from "./../../images/onlineagent/onlineagent-2-1x.png";
import onlineagent22x from "./../../images/onlineagent/onlineagent-2-2x.png";
import onlineagent31x from "./../../images/onlineagent/onlineagent-3-1x.png";
import onlineagent32x from "./../../images/onlineagent/onlineagent-3-2x.png";
import onlineagent41x from "./../../images/onlineagent/onlineagent-4-1x.png";
import onlineagent42x from "./../../images/onlineagent/onlineagent-4-2x.png";
import onlineagent51x from "./../../images/onlineagent/onlineagent-5-1x.png";
import onlineagent52x from "./../../images/onlineagent/onlineagent-5-2x.png";

import imgConclusion from "./../../img/imgConclusion.svg";
import imgNumber1 from "./../../img/imgNumber1.svg";
import imgNumber2 from "./../../img/imgNumber2.svg";
import imgNumber3 from "./../../img/imgNumber3.svg";
import imgNumber4 from "./../../img/imgNumber4.svg";
import imgNumber5 from "./../../img/imgNumber5.svg";

function OnlineAgent(props) {

	const [onlineagent1WD, setOnlineagent1WD] = useState('');
	const [onlineagent2WD, setOnlineagent2WD] = useState('');
	const [onlineagent3WD, setOnlineagent3WD] = useState('');
	const [onlineagent4WD, setOnlineagent4WD] = useState('');
	const [onlineagent5WD, setOnlineagent5WD] = useState('');
	
   useEffect(() => {
	  if( props.wd < 1200 ){
		  setOnlineagent1WD(onlineagent11x);
		  setOnlineagent2WD(onlineagent21x);
		  setOnlineagent3WD(onlineagent31x);
		  setOnlineagent4WD(onlineagent41x);
		  setOnlineagent5WD(onlineagent51x);
	  } else if( props.wd >= 1200 ){
		  setOnlineagent1WD(onlineagent12x);
		  setOnlineagent2WD(onlineagent22x);
		  setOnlineagent3WD(onlineagent32x);
		  setOnlineagent4WD(onlineagent42x);
		  setOnlineagent5WD(onlineagent52x);
	  }
   },[props.wd]);
  
  return (

      <div>
        <Container>
          <div className="wrapBG">
            <Row className="wrapRowOnlineAgent1">
              <Col xl="6" lg="6" md="12" sm="12">
                <h2 className="h2APISalesTool">
                  <span className="h2ChatBotsTextBlue">Online</span> Agent
                </h2>
                <p className="pChatBotsText">
                  We excel in crafting processes designed to attract new customers for insurance companies through the strategic implementation of a referral program and innovative IT solutions.
                </p>
                <a href="/en/contact" className="btn buttonOne">Contact us</a>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img
                    src={onlineagent1WD}
                    alt=" "
                    className="imgOnlineAgent1"
                />
              </Col>
            </Row>
            <Row className="wrapRowOnlineAgent2">
              <Col xl="12" lg="12" md="12" sm="12">
                <h2 className="h2TechStacksText">Tech stacks</h2>
              </Col>
            </Row>
            <Row className="wrapRowTechStacksImg">
              <Col xl="12" lg="12" md="12" sm="12">
                <div id="marquee1" className="marquee">
                  <div id="blockSlider" className="marquee__inner">
                    <Techstacks />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="wrapRowOnlineAgent4">
              <Col xl="6" lg="6" md="12" sm="12">
                <h2 className="h2OnlineAgent">CUSTOMER CHALLENGES</h2>
                <p className="pCustomerProblems">
                  The objective of the Insurance Company was to establish a referral program to attract new customers.
                  <br/>
                  <br/>
                  The referral program's features included:
                </p>
                <ul className="ulCustomerProblems">
                  <li><b>Swift Agent Onboarding:</b> The process of connecting potential agents had to be accomplished within minutes, without the need for them to grasp complex insurance concepts.</li>
                  <li><b>Online Agent Operations:</b> Agents were expected to conduct their work entirely online, without the requirement to visit the company's physical office.</li>
                  <li><b>User-Friendly Lead Submission:</b> Even if a potential client merely wanted to refer a lead to the insurance company and earn a commission, this action should be straightforward, rapid, and convenient.</li>
                  <li><b>Accessibility for Non-IT Experts:</b> It should be easy for potential agents, including individuals unaccustomed to the IT world, to comprehend and engage with the insurance company's IT solution.</li>
                  <li><b>Streamlined Lead Transfer:</b> Agents should spend no more than a couple of minutes to submit a lead, with the insurance company taking responsibility for the remaining client interactions.</li>
                </ul>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img
                    src={onlineagent2WD}
                    alt=" "
                    className="imgOnlineAgent2"
                />
              </Col>
            </Row>
            <Row className="wrapRowOnlineAgent5">
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="imgApiSalesTool2Top"
              >
                <img
                    src={onlineagent3WD}
                    alt=" "
                    className="imgOnlineAgent3"
                />
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <p className="pAPISalesTool2">
                  To realize this process, the decision was made to develop a landing page on the client's website. This landing page would contain promotional content and a link to a chatbot, through which potential agents could engage with the insurance company at all stages of the referral program.
                  <br />
                  <br />
                  The chatbot would empower agents to:
                  <br />- Initiate contact with the insurance company.
                  <br />- Submit potential leads to the insurance company.
                  <br />- Monitor the status of transactions associated with their leads.
                  <br />- Seek advice from insurance company specialists.
                  <br />
                  <br />
                  Building such a process necessitated the integration of the chatbot with the company's CRM, website, and other back-office systems.
                </p>
              </Col>
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="imgApiSalesTool2Bottom"
              >
                <img
                    src={onlineagent3WD}
                    alt=" "
                    className="imgOnlineAgent3"
                />
              </Col>
            </Row>
            <Row className="wrapRowOnlineAgent6">
              <Col xl="12" lg="12" md="12" sm="12">
                <h2 className="h2DevelopmentProcess">DEVELOPMENT PROCESS</h2>
              </Col>
            </Row>
            <Row className="wrapRowDevelopmentProcessBlock">
              <Col xl="12" lg="12" md="12" sm="12">
                <div className="itemImgNumber itemImgNumber1">
                  <img
                      src={imgNumber1}
                      alt=""
                      className="imgNumber imgNumber1"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Solution Concept and Problem Definition:</b>
                    <br/>- Collaborate with the client to establish the general concept of the solution and define the primary problems to be addressed. This step sets the foundation for the project.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber2">
                  <img
                      src={imgNumber2}
                      alt=""
                      className="imgNumber imgNumber2"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Regular Status Meetings:</b>
                    <br/>- Conduct weekly status meetings with the project sponsor and key members of the working group. These meetings serve as a platform for ongoing communication and alignment with project goals.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber3">
                  <img
                      src={imgNumber3}
                      alt=""
                      className="imgNumber imgNumber3"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Action Plans and Concept Agreements:</b>
                    <br/>- Develop action plans and agree upon solution concepts and mockups. This iterative process helps in creating a shared vision and understanding of the solution's direction.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber4">
                  <img
                      src={imgNumber4}
                      alt=""
                      className="imgNumber imgNumber4"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Demonstrating Development Progress:</b>
                    <br/>Showcase intermediate development results to the client and project stakeholders. These demonstrations provide transparency and allow for early feedback and adjustments.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber5">
                  <img
                      src={imgNumber5}
                      alt=""
                      className="imgNumber imgNumber5"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Scalability Planning:</b>
                    <br/>Discuss and adjust scalability plans for the future of the solution. Scalability is crucial to accommodate growing demands and changes in the insurance industry.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="wrapRowOnlineAgent7">
              <Col xl="12" lg="12" md="12" sm="12">
                <h2 className="h2DevelopmentProcess">RESULTS</h2>
              </Col>
            </Row>
            <Row className="wrapRowOnlineAgent5">
              <Col xl="6" lg="6" md="12" sm="12">
                <p className="pOnlineAgent">
                  The entire process was structured into two distinct phases:
                  <br />
                  - MVP
                  <br />
                  - Target Process
                  <br />
                  <br />
                  The MVP phase focused on establishing a minimum set of developments to validate the process in real-world scenarios. The primary objective was to ensure that the process appeared close to the target state from the outset and required minimal future adjustments.
                  <br />
                  <br />
                  Consequently, the MVP encompassed full chatbot development and integration with the CRM system and website. The development of the office back-office infrastructure and additional process automation was reserved for the second phase.
                </p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img
                    src={onlineagent4WD}
                    alt=" "
                    className="imgOnlineAgent4"
                />
              </Col>
            </Row>
            <Row className="wrapRowOnlineAgent8">
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="imgApiSalesTool2Top"
              >
                <img
                    src={onlineagent5WD}
                    alt=" "
                    className="onlineagent5WD"
                />
              </Col>
              <Col xl={{ offset: 1, span: 5 }} lg={{ offset: 1, span: 5 }} md="12" sm="12">
                <p className="pOnlineAgent">
                  The development phase lasted for 3 months, with the first phase taking just one month. This efficient approach allowed us to promptly launch and assess the functionality.
                  <br />
                  <br />
                  The team successfully developed a solution that comprehensively met all the customer's specified requirements. Subsequently, we provided training to the client's employees and launched the system.
                  <br />
                  <br />
                  The insurance company began receiving leads from potential agents on the very first day following the development's launch. This quick uptake demonstrated the system's effectiveness and immediate utility.
                </p>
              </Col>
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="imgApiSalesTool2Bottom"
              >
                <img
                    src={onlineagent5WD}
                    alt=" "
                    className="imgOnlineAgent45"
                />
              </Col>
            </Row>
            <Row className="wrapRowConclusion">
              <Col xl="6" lg="6" md="12" sm="12">
                <div className="wrapConclusionText">
                  <h2 className="h2Conclusion">CONCLUSION</h2>
                  <p className="pConclusion">
                    This case study underscores the notion that even during challenging periods, such as quarantine, it is possible to introduce new solutions that assist insurance companies in reaching and engaging new customers. Notably, these solutions also open doors for individuals without prior experience in insurance to generate additional income. The ability to adapt, innovate, and facilitate broader participation exemplifies the dynamic nature of the insurance industry and its potential for growth.
                  </p>
                  <a href="/en/contact" className="btn buttonOne">Contact us</a>
                </div>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img src={imgConclusion} alt="" className="imgConclusion" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>

  );
}

export default OnlineAgent;
