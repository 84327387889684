import React, { useState, useEffect } from "react";
import "./../../css/api-sales-tool.scss";
import { Container, Row, Col } from "react-bootstrap";

import "react-multi-carousel/lib/styles.css";
import Techstacks from "./../../component/techstacks";

import apisalestool11x from "./../../images/apisalestool/apisalestool-1-1x.png";
import apisalestool12x from "./../../images/apisalestool/apisalestool-1-2x.png";
import apisalestool21x from "./../../images/apisalestool/apisalestool-2-1x.png";
import apisalestool22x from "./../../images/apisalestool/apisalestool-2-2x.png";
import apisalestool31x from "./../../images/apisalestool/apisalestool-3-1x.png";
import apisalestool32x from "./../../images/apisalestool/apisalestool-3-2x.png";
import apisalestool41x from "./../../images/apisalestool/apisalestool-4-1x.png";
import apisalestool42x from "./../../images/apisalestool/apisalestool-4-2x.png";
import apisalestool51x from "./../../images/apisalestool/apisalestool-5-1x.png";
import apisalestool52x from "./../../images/apisalestool/apisalestool-5-2x.png";

import imgNumber1 from "./../../img/imgNumber1.svg";
import imgNumber2 from "./../../img/imgNumber2.svg";
import imgNumber3 from "./../../img/imgNumber3.svg";
import imgNumber4 from "./../../img/imgNumber4.svg";
import imgNumber5 from "./../../img/imgNumber5.svg";
import imgConclusion from "./../../img/imgConclusion.svg";

function APISalesTool(props) {

	const [apisalestool1WD, setApisalestool1WD] = useState('');
	const [apisalestool2WD, setApisalestool2WD] = useState('');
	const [apisalestool3WD, setApisalestool3WD] = useState('');
	const [apisalestool4WD, setApisalestool4WD] = useState('');
	const [apisalestool5WD, setApisalestool5WD] = useState('');
	
   useEffect(() => {
	  if( props.wd < 1200 ){
		  setApisalestool1WD(apisalestool11x);
		  setApisalestool2WD(apisalestool21x);
		  setApisalestool3WD(apisalestool31x);
		  setApisalestool4WD(apisalestool41x);
		  setApisalestool5WD(apisalestool51x);
	  } else if( props.wd >= 1200 ){
		  setApisalestool1WD(apisalestool12x);
		  setApisalestool2WD(apisalestool22x);
		  setApisalestool3WD(apisalestool32x);
		  setApisalestool4WD(apisalestool42x);
		  setApisalestool5WD(apisalestool52x);
	  }
   },[props.wd]);
  
  return (
    <div>
      <Container>
        <div className="wrapBG">
          <Row className="wrapRowAPISalesTool1">
            <Col xl="6" lg="6" md="12" sm="12">
              <h2 className="h2APISalesTool">
                <span className="h2ChatBotsTextBlue">API</span> - Sales Tool
              </h2>
              <p className="pChatBotsText">
                Our expertise lies in developing solutions that enhance the sales process for insurance products through the strategic utilization of APIs.
              </p>
              <a href="/en/contact" className="btn buttonOne">Contact us</a>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img
                src={apisalestool1WD}
                alt=" "
                className="imgApiSalesTool1"
              />
            </Col>
          </Row>
          <Row className="wrapRowTechStacksText">
            <Col xl="12" lg="12" md="12" sm="12">
              <h2 className="h2TechStacksText">Tech stacks</h2>
            </Col>
          </Row>
          <Row className="wrapRowTechStacksImg">
            <Col xl="12" lg="12" md="12" sm="12">
              <div id="marquee1" className="marquee">
                <div id="blockSlider" className="marquee__inner">
                  <Techstacks />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="wrapRowAPISalesTool3">
            <Col xl="6" lg="6" md="12" sm="12">
              <h2 className="h2APISalesTool2">CUSTOMER CHALLENGES</h2>
              <p className="pAPISalesTool2">
                Our client aimed to establish a sales process for insurance products in collaboration with partners, achieved through API integration. The primary challenge our client faced was to provide standard APIs that would enable partners to seamlessly incorporate the entire insurance sales process within their own IT systems.
                <br />
                On the client's side, it was crucial to have mechanisms for configuring business rules, tariff calculations, and the sales scenarios for insurance products.
              </p>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img
                src={apisalestool2WD}
                alt=" "
                className="imgApiSalesTool2"
              />
            </Col>
          </Row>
          <Row className="wrapRowAPISalesTool4">
            <Col xl="6" lg="6" md="12" sm="12" className="imgApiSalesTool2Top">
              <img
                src={apisalestool3WD}
                alt=" "
                className="imgApiSalesTool3"
              />
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <p className="pAPISalesTool2">
                This would allow partners connected to the API to sell insurance products of varying complexity, complete with online signing and payment, without the need to implement intricate insurance rules within their IT systems. In turn, the insurance company would have centralized control over and the ability to modify business rules, tariffs, and other settings for insurance products.
                <br />
                <br />
                During the implementation of this solution, the client placed a strong emphasis on obtaining a tool for configuring insurance products that were to be sold through the API. The key requirement was that most settings could be configured within the system, minimizing the necessity for extensive development work.
              </p>
            </Col>
            <Col
              xl="6"
              lg="6"
              md="12"
              sm="12"
              className="imgApiSalesTool2Bottom"
            >
              <img
                src={apisalestool3WD}
                alt=" "
                className="imgApiSalesTool3"
              />
            </Col>
          </Row>
          <Row className="wrapRowDevelopmentProcessText">
            <Col xl="12" lg="12" md="12" sm="12">
              <h2 className="h2DevelopmentProcess">DEVELOPMENT PROCESS</h2>
            </Col>
          </Row>
          <Row className="wrapRowDevelopmentProcessBlock">
            <Col xl="12" lg="12" md="12" sm="12">
              <div className="itemImgNumber itemImgNumber1">
                <img src={imgNumber1} alt="" className="imgNumber imgNumber1" />
                <p className="pDevelopmentProcess">
                  <b>Solution Concept and Problem Definition:</b>
                  <br/>- Collaborate with the client to establish the general concept of the solution and define the primary problems to be addressed. This step sets the foundation for the project.
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber2">
                <img src={imgNumber2} alt="" className="imgNumber imgNumber2" />
                <p className="pDevelopmentProcess">
                  <b>Regular Status Meetings:</b>
                  <br/>- Conduct weekly status meetings with the project sponsor and key members of the working group. These meetings serve as a platform for ongoing communication and alignment with project goals.
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber3">
                <img src={imgNumber3} alt="" className="imgNumber imgNumber3" />
                <p className="pDevelopmentProcess">
                  <b>Action Plans and Concept Agreements:</b>
                  <br/>- Develop action plans and agree upon solution concepts and mockups. This iterative process helps in creating a shared vision and understanding of the solution's direction.
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber4">
                <img src={imgNumber4} alt="" className="imgNumber imgNumber4" />
                <p className="pDevelopmentProcess">
                  <b>Demonstrating Development Progress:</b>
                  <br/>Showcase intermediate development results to the client and project stakeholders. These demonstrations provide transparency and allow for early feedback and adjustments.
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber5">
                <img src={imgNumber5} alt="" className="imgNumber imgNumber5" />
                <p className="pDevelopmentProcess">
                  <b>Scalability Planning:</b>
                  <br/>Discuss and adjust scalability plans for the future of the solution. Scalability is crucial to accommodate growing demands and changes in the insurance industry.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="wrapRowAPISalesTool5">
            <Col xl="12" lg="12" md="12" sm="12">
              <h2 className="h2DevelopmentProcess">RESULTS</h2>
            </Col>
          </Row>
          <Row className="wrapRowAPISalesTool6">
            <Col xl="6" lg="6" md="12" sm="12">
              <p className="pAPISalesTool2">
                To realize our objectives, we configured and implemented several modules of the Sagacity CRM system, specifically:
                <br />- Sagacity API Gateway
                <br />- Sagacity Business Rules
                <br />
                <br />
                The development phase spanned a duration of 3 months, during which the team adeptly addressed all the requirements specified by the customer. As part of the development, APIs were established for products such as MTPL, VTPL, and Travel.
                <br />
                Furthermore, successful integration was achieved with several partners, including online insurance service aggregators and banks.
              </p>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img
                src={apisalestool4WD}
                alt=" "
                className="imgApiSalesTool4"
              />
            </Col>
          </Row>

          <Row className="wrapRowAPISalesTool7">
            <Col xl="6" lg="6" md="12" sm="12" className="imgApiSalesTool2Top">
              <img
                src={apisalestool5WD}
                alt=" "
                className="imgApiSalesTool5"
              />
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <p className="pAPISalesTool2 pAPISalesTool2margin">
                In cases where partners could only integrate via their data exchange protocols, supplementary development work was undertaken to ensure API compatibility.
                <br />
                <br />
                Following the launch of the developed solution, new business processes for the sale of electronic insurance contracts were established, standardizing the onboarding of new partners and instituting a support framework.
                <br />
                <br />
                Future steps, in collaboration with the client, involve the expansion of the product range available through the API to encompass Casco, Health, and Property insurance products. This underlines the commitment to ongoing innovation and product development.
              </p>
            </Col>
            <Col
              xl="6"
              lg="6"
              md="12"
              sm="12"
              className="imgApiSalesTool2Bottom"
            >
              <img
                src={apisalestool5WD}
                alt=" "
                className="imgApiSalesTool5"
              />
            </Col>
          </Row>
          <Row className="wrapRowConclusion">
            <Col xl="6" lg="6" md="12" sm="12">
              <div className="wrapConclusionText">
                <h2 className="h2Conclusion">CONCLUSION</h2>
                <p className="pConclusion">
                  This case study underscores the notion that even during challenging periods, such as quarantine, it is possible to introduce new solutions that assist insurance companies in reaching and engaging new customers. Notably, these solutions also open doors for individuals without prior experience in insurance to generate additional income. The ability to adapt, innovate, and facilitate broader participation exemplifies the dynamic nature of the insurance industry and its potential for growth.
                </p>
                <a href="/en/contact" className="btn buttonOne">Contact us</a>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img src={imgConclusion} alt="" className="imgConclusion" />
            </Col>
          </Row>
        </div>
      </Container>
    </div>

);
}

export default APISalesTool;
