import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./../../css/online-store.scss";
import "react-multi-carousel/lib/styles.css";
import Techstacks from "./../../component/techstacks";

import mobileapp11x from "./../../images/mobileapp/mobileapp-1-1x.png";
import mobileapp12x from "./../../images/mobileapp/mobileapp-1-2x.png";
import mobileapp21x from "./../../images/mobileapp/mobileapp-2-1x.png";
import mobileapp22x from "./../../images/mobileapp/mobileapp-2-2x.png";
import mobileapp31x from "./../../images/mobileapp/mobileapp-3-1x.png";
import mobileapp32x from "./../../images/mobileapp/mobileapp-3-2x.png";
import mobileapp41x from "./../../images/mobileapp/mobileapp-4-1x.png";
import mobileapp42x from "./../../images/mobileapp/mobileapp-4-2x.png";
import mobileapp51x from "./../../images/mobileapp/mobileapp-5-1x.png";
import mobileapp52x from "./../../images/mobileapp/mobileapp-5-2x.png";

import imgNumber1 from "./../../img/imgNumber1.svg";
import imgNumber2 from "./../../img/imgNumber2.svg";
import imgNumber3 from "./../../img/imgNumber3.svg";
import imgNumber4 from "./../../img/imgNumber4.svg";
import imgNumber5 from "./../../img/imgNumber5.svg";
import imgConclusion from "./../../img/imgConclusion.svg";

function MobileApp(props) {

	const [mobileapp1WD, setMobileapp1WD] = useState('');
	const [mobileapp2WD, setMobileapp2WD] = useState('');
	const [mobileapp3WD, setMobileapp3WD] = useState('');
	const [mobileapp4WD, setMobileapp4WD] = useState('');
	const [mobileapp5WD, setMobileapp5WD] = useState('');
	
   useEffect(() => {
	  if( props.wd < 1200 ){
		  setMobileapp1WD(mobileapp11x);
		  setMobileapp2WD(mobileapp21x);
		  setMobileapp3WD(mobileapp31x);
		  setMobileapp4WD(mobileapp41x);
		  setMobileapp5WD(mobileapp51x);
	  } else if( props.wd >= 1200 ){
		  setMobileapp1WD(mobileapp12x);
		  setMobileapp2WD(mobileapp22x);
		  setMobileapp3WD(mobileapp32x);
		  setMobileapp4WD(mobileapp42x);
		  setMobileapp5WD(mobileapp52x);
	  }
   },[props.wd]);
  
  return (

      <div>
        <Container>
          <div className="wrapBG">
            <Row className="wrapRowMobileApp1">
              <Col xl="5" lg="5" md="12" sm="12">
                <h2 className="h2OnlineStore h2MobileApp1">
                  <span>Mobile</span> App
                </h2>
                <p className="pOnlineStore">
                  We specialize in creating mobile applications that simplify the insurance process for our clients' customers, enhancing their experience and streamlining insurance-related tasks.
                </p>
                <a href="/en/contact" className="btn buttonOne buttonProduct">Contact us</a>
              </Col>
              <Col xl={{ span: 5, offset: 2 }} lg={{ span: 5, offset: 2 }} md="12" sm="12">
                <img src={mobileapp1WD} alt="" className="imgOnlineStore1" />
              </Col>
            </Row>
            <Row className="wrapRowMobileApp2">
              <Col xl="12" lg="12" md="12" sm="12">
                <h2 className="h2TechStacksText">Tech stacks</h2>
              </Col>
            </Row>
            <Row className="wrapRowMobileApp3">
              <Col xl="12" lg="12" md="12" sm="12">
                <div id="marquee1" className="marquee">
                  <div id="blockSlider" className="marquee__inner">
                    <Techstacks />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="wrapRowMobileApp4">
              <Col xl="7" lg="7" md="12" sm="12">
                <h2 className="h2OnlineStore2  h2MobileApp1">CUSTOMER CHALLENGES</h2>
                <p className="pCustomerProblems">
                  The client's objective was to create a mobile application that would allow customers to access all their contracts and their status with the insurance company. The app was required to display the following types of contracts:
                  <br/>
                  <br/>
                  <b>- Insurance Contracts Purchased In-Person:</b> Contracts that were purchased at the insurance company's physical office.
                  <br/>
                  <b>- Online Insurance Contracts:</b> Contracts that were bought through the company's website, using online channels.
                  <br/>
                  <br/>
                  Customers expected the mobile application to provide detailed information within their contracts, including:
                  <br/>
                  <br/>
                  <b>- Type of Insurance:</b> the specific insurance coverage or policy type associated with each contract.
                  <br/>
                  <br/>
                  <b>- Insurance Contract Number:</b> a unique identifier for each insurance contract.
                  <br/>
                  <br/>
                  <b>- Insurance Program:</b> the details of the insurance program or plan associated with the contract.
                  <br/>
                  <br/>
                  <b>- Contract Duration:</b> the period for which the contract is valid.
                  <br/>
                  <br/>
                  <b>- Contact Information:</b> relevant contact details for communication with the insurance company.
                  <br/>
                </p>
                <br/>
                <p className="pCustomerProblems">
                  This comprehensive information would enable customers to access all their contracts quickly and eliminate the need to search for this data in physical documents or emails at home. The goal was to offer a convenient and centralized platform for managing insurance contracts and related
                </p>

              </Col>
              <Col xl="5" lg="5" md="12" sm="12">
                <img
                    src={mobileapp2WD}
                    alt=""
                    className="imgCustomerProblems1"
                />
              </Col>
            </Row>
            <Row className="wrapRowMobileApp5">
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="colImgCustomerProblems3Top"
              >
                <img
                    src={mobileapp3WD}
                    alt=""
                    className="imgCustomerProblems3"
                />
              </Col>
              <Col xl={{ offset: 1, span: 5 }} lg={{ offset: 1, span: 5 }} md="12" sm="12">
                <p className="pOnlineStore1">
                  To meet the client's objectives of keeping customers engaged online and facilitating rapid issue resolution, the mobile application was designed with the following key features:
                  <br/><br/>
                  <b>- In-App Car Insurance Purchase:</b> Customers could purchase car insurance directly within the app. Following the purchase, the agreement would automatically appear in the "Contracts" section of the mobile application, providing a seamless and immediate experience.
                  <br/><br/>
                  <b>- Family Insurance Management:</b> The application allowed customers to add insurance contracts for their family members and relatives to their account. After registration, these family agreements were included in the "Contracts" section. This feature ensured convenient management of insurance contracts for the entire family and simplified the process for parents.
                  <br/><br/>
                  <b>- In-App Chat Support:</b> Customers had access to an in-app chat feature, providing a convenient platform for addressing queries and obtaining assistance. The chat allowed customers to select the relevant topic for their inquiries, such as Medicine, Insurance Policy Purchase, Insurance Event and more. This feature facilitated quick and efficient issue resolution and communication with the insurance company.
                  <br/><br/>
                </p>
              </Col>
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="colImgCustomerProblems3Bottom"
              >
                <img
                    src={mobileapp3WD}
                    alt=""
                    className="imgCustomerProblems3"
                />
              </Col>
            </Row>
            <Row className="wrapRowMobileApp6">
              <Col xl="7" lg="7" md="12" sm="12">
                <p className="pCustomerProblems">
                  The registration process for customers in the mobile application was streamlined, with an emphasis on simplicity and convenience. It entailed two straightforward steps:
                </p>
                <p className="pCustomerProblems">
                  <b>1. Providing Personal Information:</b> Customers were required to input their mobile phone number and date of birth. This information served as their primary identification.
                <br/><br/>
                  <b>2. One-Time Password Verification:</b> After entering their mobile phone number and date of birth, customers received a one-time password on the same phone number. They were then prompted to enter this password into the required field for verification.
                <br/><br/>
                </p>
                <p className="pCustomerProblems">
                  Notably, if customers provided incorrect mobile phone numbers or dates of birth during registration, the app would not display their contracts. In such cases, customers were encouraged to contact a specialist via the in-app chat for data verification.
                  <br/>
                  The mobile application also featured several user-friendly settings, including:
                </p>
                <ul className="ulCustomerProblems">
                  <li><b> "Remember in the System" Function:</b> This feature allowed customers to activate the option, eliminating the need to enter a onetime password each time they logged into the application. Upon opening the app, customers would immediately access their accounts and view their contracts.</li>
                  <li><b> "Customers Who Have Added Your Contracts" Function:</b> This function showed users of the mobile app to whom the customer had granted access to their contracts. Customers had the option to delete such users, thereby ensuring they saw only their insurance contracts.</li>
                  <li><b> "Clients You Have Added" Function:</b> This function displayed the contracts of clients whom the customer had added to their account. Customers could delete these added clients, ensuring that they viewed only their insurance contracts. These features aimed to enhance user privacy and provide a clear view of their insurance agreements.</li>
                </ul>
              </Col>
              <Col xl="5" lg="5" md="12" sm="12">
                <img
                    src={mobileapp4WD}
                    alt=""
                    className="imgCustomerProblems3"
                />
              </Col>
            </Row>
            <Row className="wrapRowMobileApp7">
              <Col>
                <h2 className="h2OnlineStore1 h2MobileAppDP">DEVELOPMENT PROCESS</h2>
              </Col>
            </Row>
            <Row className="wrapRowMobileApp8">
              <Col xl="12" lg="12" md="12" sm="12">
                <div className="itemImgNumber itemImgNumber1">
                  <img
                      src={imgNumber1}
                      alt=""
                      className="imgNumber imgNumber1"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Solution Concept and Problem Definition:</b>
                    <br/>- Collaborate with the client to establish the general concept of the solution and define the primary problems to be addressed. This step sets the foundation for the project.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber2">
                  <img
                      src={imgNumber2}
                      alt=""
                      className="imgNumber imgNumber2"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Regular Status Meetings:</b>
                    <br/>- Conduct weekly status meetings with the project sponsor and key members of the working group. These meetings serve as a platform for ongoing communication and alignment with project goals.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber3">
                  <img
                      src={imgNumber3}
                      alt=""
                      className="imgNumber imgNumber3"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Action Plans and Concept Agreements:</b>
                    <br/>- Develop action plans and agree upon solution concepts and mockups. This iterative process helps in creating a shared vision and understanding of the solution's direction.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber4">
                  <img
                      src={imgNumber4}
                      alt=""
                      className="imgNumber imgNumber4"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Demonstrating Development Progress:</b>
                    <br/>Showcase intermediate development results to the client and project stakeholders. These demonstrations provide transparency and allow for early feedback and adjustments.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber5">
                  <img
                      src={imgNumber5}
                      alt=""
                      className="imgNumber imgNumber5"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Scalability Planning:</b>
                    <br/>Discuss and adjust scalability plans for the future of the solution. Scalability is crucial to accommodate growing demands and changes in the insurance industry.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="wrapRowMobileApp9">
              <Col>
                <h2 className="h2OnlineStore1">Team</h2>
                <p className="pCustomerProblems tacenter">
                  The development was conducted according to the
                  <br/>
                  flexible methodology of Agile
                </p>
              </Col>
            </Row>
            <Row className="wrapRowMobileApp10">
              <Col xl="3" lg="4" md="6" sm="12" className="col-team col-team1">
                <h2 className="p-team p-team1">Project Manager</h2>
              </Col>
              <Col xl="3" lg="4" md="6" sm="12" className="col-team col-team2">
                <h2 className="p-team p-team2">Business Analyst</h2>
              </Col>
              <Col xl="3" lg="4" md="6" sm="12" className="col-team col-team3">
                <h2 className="p-team p-team3">BackEnd Developer</h2>
              </Col>
              <Col xl="3" lg="4" md="6" sm="12" className="col-team col-team4">
                <h2 className="p-team p-team4">Mobile Developer</h2>
              </Col>
              <Col xl={{ span: 3, offset: 3 }} lg="4" md="6" sm="12" className="col-team col-team5">
                <h2 className="p-team p-team5">QA Engineer</h2>
              </Col>
              <Col xl="3" lg="4" md="6" sm="12" className="col-team col-team6">
                <h2 className="p-team p-team6">Designer</h2>
              </Col>
            </Row>
            <Row className="wrapRowMobileApp11">
              <Col>
                <h2 className="h2OnlineStore1">RESULTS</h2>
              </Col>
            </Row>
            <Row className="wrapRowMobileApp12">
              <Col xl="6" lg="6" md="12" sm="12">
                <p className="pOnlineStore1">
                  The mobile application development project spanned a period of three months, during which the development team successfully created an app that addressed all the specific requirements outlined by the customer.<br/>
                  <br/><br/>
                  The mobile application empowered customers to:
                  <br/><br/>
                  - Manage insurance-related matters and address a variety of issues without the need to visit the insurance company's office or use a computer.
                  <br/><br/>
                  - Seamlessly handle their insurance affairs via their mobile phones, offering the convenience of on-the-go management, whether on the way to work or while traveling.
                  <br/><br/>
                  - With the initial development phase completed and the mobile application deployed, further phases of the project's development were planned, signifying ongoing efforts to enhance the application's functionality and cater to the evolving needs of the client and their customers. This marked the beginning of a journey toward continued improvement and innovation in the insurance industry.
                </p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img
                    src={mobileapp5WD}
                    alt=""
                    className="imgResultsProduct1"
                />
              </Col>
            </Row>
            <Row className="wrapRowConclusion">
              <Col xl="6" lg="6" md="12" sm="12">
                <div className="wrapConclusionText">
                  <h2 className="h2Conclusion">CONCLUSION</h2>
                  <p className="pConclusion">
                    This case study underscores the notion that even during challenging periods, such as quarantine, it is possible to introduce new solutions that assist insurance companies in reaching and engaging new customers. Notably, these solutions also open doors for individuals without prior experience in insurance to generate additional income. The ability to adapt, innovate, and facilitate broader participation exemplifies the dynamic nature of the insurance industry and its potential for growth.
                  </p>
                  <a href="/en/contact" className="btn buttonOne">Contact us</a>
                </div>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img src={imgConclusion} alt="" className="imgConclusion" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>

  );
}

export default MobileApp;
